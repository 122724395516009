import { gsap } from 'gsap';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eBaseEventTypes from 'Engine/enums/eBaseEventTypes';
import SoundManager from 'Engine/soundManager/SoundManager';
import EntryPoint from 'Engine/EntryPoint';
import ControllerAntisipators from 'Engine/base/antisipators/ControllerAntisipators';

export default class AntisipatorsController extends ControllerAntisipators {

  checkAntisipators(data) {
    this.reset();
    if (EntryPoint.GameModel.freespinsEnabled && !EntryPoint.GameModel.freespinsTriggered) {
      return
    }

    data.forEach((reelData, reelId) => {
      if (this._spacialSymbolCount >= this._minCountForStartAnimation
        && this._spacialSymbolCount <= this._maxCountForStartAnimation
        && reelId >= this.startsFromReelIndex && reelId <= this.endsOnReelIndex) {
        this._involvedReels.push(reelId);
        this.isAntisipator = true;
      }
      for (let symbolId of reelData) {
        if (this._triggerSymbolsId.includes(symbolId)) this._spacialSymbolCount++;
      }
    });

    this._totalInvolvedReelsCount = this._involvedReels.length;
  }

  tryStartAntisipatorForReel(reelId) {
    if (!this.isAntisipator || !this._involvedReels.includes(reelId) || !this._animations[reelId]) return;
    this._animations[reelId].visible = true;
    gsap.to(this._animations[reelId], { alpha: 1, duration: this._showDuration });
    if (this._totalInvolvedReelsCount === this._involvedReels.length) {
      GlobalDispatcher.dispatch(eBaseEventTypes.EBET_ANTISIPATOR__START, reelId);
      this._mainSoundType && SoundManager.play(this._mainSoundType, this._mainSoundVolume, false, this._mainSoundGroup);
    }
  }

  tryStopAntisipatorOnReel(reelId) {
    if (!this.isAntisipator || !this._involvedReels.includes(reelId) || !this._animations[reelId]) return;

    gsap.to(this._animations[reelId], {
      duration: this._hideDuration,
      alpha: 0,
      onComplete: () => this._animations[reelId].visible = false
    });

    this._involvedReels = this._involvedReels.filter(id => id !== reelId);

    if (!this._involvedReels.length) {
      GlobalDispatcher.dispatch(eBaseEventTypes.EBET_ANTISIPATOR__STOP, reelId);
      this._mainSoundType && SoundManager.stop(this._mainSoundType);
      this._tileSoundType && SoundManager.play(this._tileSoundType, this._tileSoundVolume, false, this._tileSoundGroup);
      this.reset();
    }
  }
}
